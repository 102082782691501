.search-component-container{
    display: flex;
    width: 500px;
    height: 48px;
    align-items: center;
    gap: var(--Spacing-xs, 6px);
    border-radius: var(--Radius-md, 12px);
border: 1px solid var(--Colors-Border-Default, #90939B);
    margin-left: auto;
}

