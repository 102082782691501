.subpage-header{
    color: var(--Colors-Content-Default, #262D3C);
    display: flex;
padding: 35px var(--Spacing-xl, 20px);
align-items: center;
gap: 10px;
align-self: stretch;
    /* Heading/h4-bold */
    /* font-family: var(--fontFamily-Open-Sans, "Open Sans"); */
    font-size: var(--fontSize-28, 28px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Heading-h4, 32px);
}

.subpage-header > span{
    color: var(--Colors-Content-Action, #274D91);
    
    /* Heading/h4-bold */
    /* font-family: var(--fontFamily-Open-Sans, "Open Sans"); */
    font-size: var(--fontSize-28, 28px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Heading-h4, 32px);
    
}

.subpage-subHeader{
    display: flex;
    align-items: center;
    height: 3rem;
    background-color: #f4f7fc;
    color: #3a598a !important;
    padding: 2rem;
    font-weight: bold;color: var(--Colors-Surface-Action, #274D91);

    /* Heading/h5-semibold */
    font-size: var(--fontSize-24, 24px);
    font-style: normal;
    font-weight: var(--fontWeight-semibold, 600);
    line-height: var(--Typography-Line-Height-Heading-h5, 28px); /* 116.667% */
}
.price-element-container{
    display: flex;
    width: 364px;
    height: 45px;
    padding: 16.5px 40px;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
background: var(--Colors-Surface-Default, #FFF);
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
}
.price-element-container:hover{
    background: var(--Colors-Surface-Default, #FFF)!important;

    box-shadow: 0px 1px 2px 0px rgba(37, 76, 150, 0.25), 0px 1px 4px 1px rgba(37, 76, 150, 0.25);
}
.price-element-container > span{
    color: var(--Colors-Content-Default, #262D3C);

    /* Body/Body-lg-bold */
    font-size: var(--fontSize-18, 18px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Body-lg, 22px); /* 122.222% */
}

.type-major-container{
    display: flex;
    padding: 48px 0px 64px 0px;
    padding-left:48px;
    justify-content: start;
    display: flex;
align-items: center;
align-content: center;
gap: 16px var(--Spacing-lg, 16px);
align-self: stretch;
flex-wrap: wrap;
}
.pricing-major{
    height: 100vh; /* or any fixed height */
    overflow: hidden;  
    display: flex;
    flex-direction: column;
}
.pricing-main {
    flex: 1;
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 1rem;  /* Enable scrolling */
}


.name-container{
    display: flex;
    width: 100%;    /* padding: 48px 0px; */
    flex-direction: column;
    align-items: center;
    /* gap: 48px; */
    align-self: stretch; 
    border-radius: 16px!important;
    background: var(--Colors-Surface-Default, #FFF);

/* Elevations/E1 */
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
}

.minor-container-second {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex: 1 0 50%;
    max-width: calc((100% - 26px)/ 2);
    padding: 29.44px 23.92px;
    gap: 22.08px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 14.72px;
    background: var(--Colors-Surface-Default, #FFF);
}