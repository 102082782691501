:root {
  --primary-dark: #274D91; /* Replace with actual color */
  --primary-plan: #123456; /* Replace with actual color */
  --primary-light: #FFFFFF; /* Replace with actual color */
}
.custom-primary {
  border-radius: var(--Radius-md, 12px);
background: var(--Colors-Surface-Action, #274D91);

/* Elevations/E1 */
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
  display: flex;
height: 48px;
padding: var(--Spacing-xl, 20px) var(--Spacing-xxl, 24px);
justify-content: center;
align-items: center;
gap: var(--Scale-6, 16px);
  color: white;
  border-color: var(--primary-dark);
  background-color: var(--primary-dark);
}

.custom-primary:hover {
  display: flex;
height: 48px;
padding: var(--Spacing-xl, 20px) var(--Spacing-xxl, 24px);
justify-content: center;
align-items: center;
gap: var(--Scale-6, 16px);
  color: var(--primary-dark) !important;
  background-color: var(--primary-light) !important;
}
.custom-extra {
  display: flex;
height: 48px;
padding: var(--Spacing-xl, 20px) var(--Spacing-xxl, 24px);
justify-content: center;
align-items: center;
border-radius: var(--Radius-md, 12px);
background: var(--Colors-Surface-Default, #FFF);

/* Elevations/E1 */
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
gap: var(--Scale-6, 16px);
  color: var(--primary-dark) !important;
  background-color: var(--primary-light) !important;
}
.custom-extra:hover {

color: var(--primary-light) !important;
background-color: var(--primary-dark) !important;
border-color: var(--primary-dark) !important;
}

.custom-secondery:hover {
  color: var(--primary-dark) !important;
  background-color: white !important;
}

.custom-secondary {
  color: var(--primary-dark);
  background-color: white;
  border-color: var(--primary-dark);
}

.custom-secondary:hover {
  color: white !important;
  border-color: var(--primary-dark) !important;
  background-color: var(--primary-dark) !important;
}

.custom-tertiary {
  color: var(--primary-plan);
  background-color: white;
  border-color: var(--primary-light);
}

.custom-tertiary:hover {
  color: var(--primary-dark) !important;
  border-color: var(--primary-dark) !important;
}


