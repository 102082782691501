.ant-drawer-title {
    color: #3a598a !important;
    font-weight: bold;
    font-size: x-large !important;
}

.form-subHeader {
    display: flex;
    align-items: center;
    height: 3rem;
    background-color: #f4f7fc;
    color: #3a598a !important;
    padding: 2rem;
    font-weight: bold;
}

.ant-drawer-body {
    padding: 0 !important
}

.form-body {
    width: 100% !important;
    padding-top: 1rem;
}

.ant-form-item-control-input-content>.ant-btn {
    margin-top: 2rem;
    background-color: #3a598a;
}
.ant-form-item-control-input-content > .ant-btn:disabled {
    background-color: #d9d9d9; 
    color: #a0a0a0; 
    cursor: not-allowed; 
    opacity: 0.6; 
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
    display: flex;
    justify-content: center;
}

.ant-select-item {
    text-align: center !important;
}

.ant-upload-doc {
    width: 200px;
    height: 75px;
    margin-bottom: 0px;
}

.doc-link-cont {
    width: 200px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #d9d9d9;

}

.custom-filter {
    display: flex;
    margin: 1rem .5rem;
    flex-direction: column;
    align-items: start;
}

.instruction-p {
    color: var(--Colors-Content-Default-2, #90939B) !important;
    text-align: center !important;
    font-size: var(--fontSize-16, 16px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Body-sm, 20px) !important;
    /* 125% */
}

label {
    display: flex;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: #000;
    font-size: var(--fontSize-18, 18px);
    font-style: normal;
    font-weight: var(--fontWeight-semibold, 600);
    /* line-height: var(--Typography-Line-Height-Body-lg, 2px); 122.222% */
}




  
  /* .ant-select-item-option {
    font-weight: bold !important;
    color: #333 !important;
    padding: 10px !important;
  }
  
  .ant-select-item-option:hover {
    background-color: #e6f7ff !important;
  }
  
  .ant-select-item-option-selected {
    background-color: #bae7ff !important;
    color: #0050b3 !important;
  } */

  .ant-upload-list-item-error .ant-upload-list-item-name {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
}
