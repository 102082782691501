.table .ant-select-selector {
  display: flex;
  align-items: center;
}

/* 
.table .ant-table {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.table .ant-table table {
  border-spacing: 0 0.75rem;
}

.table .ant-table .ant-table-thead .ant-table-cell {
  font-style: normal;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 25px;
  color: #595959;
  background-color: white;
}

.table .ant-table .ant-table-thead .ant-table-cell::before {
  background-color: white !important;
}

.table .ant-table .ant-table-tbody .ant-table-row {
  border: 1px solid #b4b4b4;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.table .ant-table .ant-table-tbody .ant-table-cell {
  font-style: normal;
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 25px;
  color: #595959;
}
*/
.ant-table-thead > tr>  .ant-table-cell {
  white-space: nowrap;
}
.table .hovered-element:hover {
  color: #c40d62;
  cursor: pointer;
  text-decoration: none;
}