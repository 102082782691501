.contract{
    color: var(--Colors-Content-Primary, #262D3C);

    font-size: var(--fontSize-24, 24px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Heading-h5, 28px); /* 116.667% */   
}

.date-picker-item > div{
    width: 100%!important;
    align-items: center!important;

}
.doc-link-cont-single{
    width: 400px!important;
}



.full-width-upload .ant-upload-list-picture-card {
    width: 100%;
  }
  
  .full-width-upload .ant-upload-list-item {
    width: 100%; /* Ensure the uploaded picture spans the full width */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .full-width-upload .ant-upload-list-item-thumbnail {
    width: 100%; /* Ensure the thumbnail image spans the full width */
    height: auto;
  }
  
  .full-width-upload .ant-upload {
    width: 100%;
  }
  
  .full-width-upload .ant-upload-list {
    display: block;
  }

  .full-width-upload .ant-upload-list-picture-card-container {
    width: 100%; /* Make container span full width */
  }
  
  .full-width-upload .ant-upload-list-item {
    width: 100%; /* Make uploaded picture span full width */
  }
  
  .full-width-upload .ant-upload-list-item-thumbnail {
    width: 100%; /* Stretch thumbnail to full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure content scales appropriately */
  }
  
  .full-width-upload .ant-upload {
    width: 100%; /* Stretch the upload area */
    justify-content: center;
  }
  
  .full-width-upload .ant-upload-list {
    width: 100%; /* Ensure uploaded list spans full width */
  }
  
  