.header-container {
  background-color: white; 
  display: inline-flex;
padding: 38px 32px 39px 1.5rem;
justify-content: flex-end;
align-items: center;
color: var(--Colors-Content-Default, #262D3C);
font-size: var(--fontSize-28, 28px);
font-style: normal;
font-weight: var(--fontWeight-bold, 700);
line-height: var(--Typography-Line-Height-Heading-h4, 32px); /* 114.286% */
}

.header-container > div > button {
  background: var(--Colors-Surface-Action, #274D91);
}
.header-container > div > button:hover {
  display: flex;
justify-content: center;
align-items: center;
gap: var(--Scale-6, 16px);
  color: var(--primary-dark) !important;
  background-color: var(--primary-light) !important;
}

.profile-main-container{
  display: flex;
  height: 56px;
  padding: 20px 16px!important;
  align-items: center;
  gap: var(--Spacing-md, 12px);
  align-self: stretch;
  border-radius: var(--Spacing-md, 12px);
background: var(--Colors-Surface-Default, #FFF);

/* Elevations/E1 */
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
}