.ant-popconfirm-buttons{
   display: flex;
   justify-content: center;
}

.ant-popconfirm-buttons > .custom-primary{
    height: auto;
    padding: 0 20px;
}

