
.ant-modal-footer{
    justify-self: end !important ;
}
.price-modal{
    display: flex;
    width: 1163px;
    padding: 48px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    /* align-items: flex-end; */
    justify-self:center;
    gap: var(--Spacing-xxxl, 32px);
}
.ant-modal-wrap{
    display: flex;  

}
.zone-side{
    display: flex;
    flex-direction: column;
width: 199px;
padding: 35px 36.8px;
justify-content: space-between;
align-items: center;
align-content: center;
row-gap: var(--Spacing-xxxl, 32px);
flex-shrink: 0;
flex-wrap: nowrap;
background: var(--Colors-Surface-Transp-Active, #F4F7FC);

}
.price-input > span > .ant-input {
    border: none;
    font-size: var(--fontSize-18, 18px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Body-lg, 22px) !important;
}

.price-header-container{
    display: flex;
width: 1155px;
padding: 23.92px 30px 23.92px 254px;
justify-content: space-between;
align-items: center;
background: var(--Colors-Surface-Transp-Active, #F4F7FC);
}

.zone-title{
    flex-shrink: 0;
    color: var(--Colors-Surface-Action, #274D91);
font-size: var(--fontSize-18, 18px);
font-style: normal;
font-weight: var(--fontWeight-bold, 700);
line-height: var(--Typography-Line-Height-Body-lg, 22px); /* 122.222% */
}

.prices-table-row {
    display: flex;
    justify-content: space-between;
    width: 100%!important;
    padding-top: var(--Spacing-xxxl, 32px);
    font-size: var(--fontSize-18, 18px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Body-lg, 22px) !important;
}
.ant-input-group-addon{
    border: none !important;
    background-color:  white !important;
    font-size: var(--fontSize-18, 18px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Body-lg, 22px) !important;
    padding-left: 0px !important;
}

.ant-modal-title{
    display: flex;
width: 1163px;
padding: 48px 0px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: var(--Spacing-xxxl, 32px);


/* Heading/h4-bold */
font-size: var(--fontSize-28, 28px) !important;
font-style: normal !important;
font-weight: var(--fontWeight-bold, 700) !important;
line-height: var(--Typography-Line-Height-Heading-h4, 32px) !important; /* 114.286% */
}
#priceList{
    width: 100%;
}

.price-modal .ant-modal-content {
    max-width: 90vw; /* Adjust this value as needed */
    overflow: auto;
  }
  
  .price-modal .ant-table-wrapper {
    overflow-x: auto;
  }
  
  .price-modal .ant-table {
    min-width: 800px; /* Adjust this value based on your table's width */
  }