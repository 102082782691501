.points-table{
    max-width: 100%;
    width: 100%;
    padding: 0px 10px;
}
.points-table thead th {
    /* background-color: rgb(255, 255, 255) !important;
    padding: 0px 24px !important;
    align-items: center !important;
    gap: var(--Spacing-xl, 20px) !important;
    color: var(--Colors-Content-Default-2, #90939B) !important;
    font-size: var(--fontSize-18, 18px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Body-lg, 22px) !important */
}

.points-table tbody.ant-table-tbody > tr{
/* border-radius: var(--Radius-md, 20px); */
background: var(--Colors-Surface-Default, #FFF);

/* Elevations/E1 */
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
} 
.points-table tbody.ant-table-tbody > tr > td{
    width: 110px;
    color: var(--Colors-Content-Default, #262D3C);

} 
.points-table tbody.ant-table-tbody > tr > td > div{
    width: 100px;
    justify-content: center;
    background-color: transparent!important;


} 
.points-table tbody.ant-table-tbody > tr > td {
    width: 100px;
    justify-self: center;
    text-align: center;
    background-color: transparent!important;

} 

body > div:nth-child(4) > div > div.ant-drawer-content-wrapper > div > div.ant-drawer-body > div > div:nth-child(2) > div > div > div > div > div > div > div > table > thead > tr > th{
    padding: 0px 24px !important;
    align-items: center !important;
    gap: var(--Spacing-xl, 20px) !important;
    color: var(--Colors-Content-Default-2, #90939B) !important;
    font-size: var(--fontSize-18, 18px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Body-lg, 22px) !important;
    background-color: #FFF !important;
}
.ant-table-placeholder > .ant-table-cell > div{
    justify-self: center!important;
    
}
