/* .ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color:'#7e22cd';
} */

a:active,
a:hover {
  color: var(--text-primary-dark);
}

.bg-wrapper-parent {
  position: relative;
  display: block;
  padding: 30px 20px;
  background: linear-gradient(to right, #ffffff 0%, #e6e0ec 50%, #978aa6 100%);
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
}

.bg-wrapper {
  background: linear-gradient(180deg, #070C33 0%, #3B024D 27.1%, #821E8A 100%);
  position: absolute;
  height: 100dvh;
  right: 0;
  z-index: -10;
}

.public-layoutC-Wrapper {
  position: relative;
  padding: 0 6%;
  height: 100dvh;
  overflow: hidden; /* Ensures the pseudo-element stays within bounds */
  display: flex;
  justify-content: end;
}

.public-layoutC-Wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/sawaBackground.png');
  background-size: cover;
  background-position: center;
  transform: scaleX(-1);
  z-index: -1; /* Keeps the background behind other content */

}
@media (max-width: 1285px) {
  .public-layoutC-Wrapper {
    justify-content: space-around !important;
  }
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.logo-container{
width: 540px;
flex-direction: column;
justify-content: center;
align-items: flex-end;
margin-right: auto;
margin-left: 100px;
}
.MuiInputBase-root{
  background-color: #ffffff;
}

.login-title > h2{
  align-self: stretch  !important;
  color: #000 !important;
  font-size: var(--fontSize-40, 40px) !important;
  font-style: normal !important;
  font-weight: var(--fontWeight-bold, 700) !important;
  line-height: var(--Typography-Line-Height-Heading-h2, 48px) !important; 
  min-width: 325 !important;;
}
.login-title > p{
  align-self: stretch  !important;
  color: var(--Colors-Content-Default-2, #90939B);

  font-size: var(--fontSize-18, 18px);
  font-style: normal;
  font-weight: var(--fontWeight-semibold, 600);
  line-height: var(--Typography-Line-Height-Body-lg, 22px); 
}