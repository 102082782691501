.main-container {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    background: var(--Colors-Surface-Container, #F5F5F6);
}

.minor-container {
    display: flex;
    align-items: flex-start;
    flex: 1 0 33%;
    max-width: calc((100% - 26px)/ 3);
    padding: 29.44px 23.92px;
    gap: 22.08px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 14.72px;
    background: var(--Colors-Surface-Default, #FFF);
}

.under-text {
    color: var(--Colors-Content-Default-2, #90939B);
    font-size: var(--fontSize-14, 14px);
    font-style: normal;
    font-weight: var(--fontWeight-semibold, 600);
    line-height: var(--Typography-Line-Height-Caption-lg, 16px);
    /* 114.286% */
}
.sec-title{
    color: var(--Colors-Content-Default, #294a92);

}
.upper-text {
    color: var(--Colors-Content-Default, #262D3C);

    /* Heading/h4-bold */
    font-size: var(--fontSize-28, 28px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Heading-h4, 32px);
    /* 114.286% */
}

.number-container {
    display: flex;
    align-items: center;
    gap: var(--Spacing-md, 12px);
}

.graph-main-container {
    width: 698px;
    height: 407.756px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--Colors-Surface-Default, #FFF);
    padding: 32px 40px;
    width: calc((100% - 13px)/ 2);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 14.72px;

}

.card-title {
    width: 396.628px;
    height: 29.615px;
    margin-bottom: 30px;
    flex-shrink: 0;
    color: var(--Colors-Content-Default, #262D3C);


    font-size: var(--fontSize-24, 24px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Heading-h5, 28px);
}

.under-graph {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--fontSize-16, 16px);
    font-style: normal;
    font-weight: var(--fontWeight-semibold, 600);
    line-height: var(--Typography-Line-Height-Body-sm, 20px);
}

.submain-cards {
    width: 445.03px;
    flex-shrink: 0;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background: var(--Colors-Surface-Default, #FFF);
    height: calc((100% - 13px)/2);

}

.piechart {
    padding: 37px 80px;

}

.map-container {
    position: relative;
    flex-shrink: 0;
    height: 776.9px;
    align-self: stretch;
    width: calc(100% - 13px - 445.03px);
    border-radius: var(--Radius-lg, 16px);
    background: var(--Colors-Surface-Default, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);


}

.container-b {
    display: flex;
    width: 100%;
    gap: 13px;
}

.container-c {
    display: flex;
    flex-direction: column;
    row-gap: 13px;
}

.map-interval {
    display: flex;
    width: 657px;
    height: 65px;
    padding: 13px 23px;
    align-items: center;
    gap: 16px;
    bottom: 25px;
    left: 50%;
    column-gap: 20px;
    /* Center horizontally */
    transform: translate(-50%, -50%);
    /* Adjust position to center */

}
.map-interval-btn{
    display: flex;
    height: 47px;
    padding: 17px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: var(--Radius-md, 12px);
    background: var(--Colors-Surface-Default, #FFF);
    color: var(--Colors-Content-Secondary, #90939B);

/* Body/Body-sm-semibold */
font-size: var(--fontSize-16, 16px);
font-style: normal;
font-weight: var(--fontWeight-semibold, 600);
line-height: var(--Typography-Line-Height-Body-sm, 20px); /* 125% */
    /* Elevations/E2 */
    box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);

}
.btn-active{
    color: var(--Colors-Content-Action, #274D91);

    /* Body/Body-sm-bold */
    font-size: var(--fontSize-16, 16px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Body-sm, 20px); /* 125% */ 
}
.calender-container {
    display: flex;
    width: 100%;
    padding: 48px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    border-radius: var(--Radius-lg, 16px);
    background: var(--Colors-Surface-Default, #FFF);

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
}

.calendar-component {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: var(--Spacing-xxs, 4px);

}

.day-card {
    display: flex;
    padding: 13px var(--Spacing-md, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: 54px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--Radius-md, 12px);
    background: var(--Colors-Surface-Default, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    color: var(--Colors-Content-Default, #262D3C);

}

.day-number {
    font-size: var(--fontSize-28, 28px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Heading-h4, 32px);
}

.trip-text {
    display: flex;
    height: 21.214px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: var(--Colors-Content-Default, #262D3C);
    font-size: var(--fontSize-18, 18px);
    font-style: normal;
    font-weight: var(--fontWeight-semibold, 600);
    line-height: var(--Typography-Line-Height-Body-lg, 22px);
}

.submain-header > p.card-title {
    width: 132.219px;
    color: #2E2E30 !important;
    font-size: var(--fontSize-20, 20px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-bold, 700) !important;
    line-height: var(--Typography-Line-Height-Heading-h6, 22px)     !important;
}
.submain-header > .card-filter {
    display: flex !important;
    width: 150px !important;
    margin-left: 4rem !important;
    height: 25.108px !important;
    padding: 5px 10px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 5px !important;
}
.card-select-item{
    display: flex !important;
    height: 25.108px !important;
    padding: 5px 10px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 1px !important;


}
.ant-select-selection-item{
    color: var(--Colors-Content-Action, #274D91) !important;
font-size: 12px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: normal !important;
}
.rank-container{
    display: flex;
    justify-content: space-between;
height: 63.871px;
padding: 10px 0px;
align-items: center;
gap: 22px;
flex-shrink: 0;
align-self: stretch;
}
.rank-number{
    color: var(--Colors-Content-Action, #274D91);
    margin-right: var(--Spacing-sm, 8px);

    ;
/* Heading/h5-bold */
font-size: var(--fontSize-24, 24px);
font-style: normal;
font-weight: var(--fontWeight-bold, 700);
line-height: var(--Typography-Line-Height-Heading-h5, 28px); /* 116.667% */
}

.rank-name {
    color: #2E2E30 !important;

    /* Body/Body-sm-bold */
    font-size: var(--fontSize-16, 16px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-bold, 700) !important;
    line-height: var(--Typography-Line-Height-Body-sm, 20px) !important; /* 125% */
}

.rank-subname {
    color: var(--Colors-Content-Default-2, #90939B) !important;

    font-size: var(--fontSize-12, 12px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Caption-sm, 14px) !important; /* 116.667% */
}
.rank-count{
    color: var(--Colors-Content-Action, #274D91);
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.cal-trip-popup{
    /* display: flex;
    width: 100%;
    padding: 48px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    border-radius: var(--Radius-lg, 16px);
    background: var(--Colors-Surface-Default, #FFF);

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15); */
}
.cal-trip-pop-container{
    /* display: flex;
    width: 100%;
    padding: 48px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    border-radius: var(--Radius-lg, 16px);
    background: var(--Colors-Surface-Default, #FFF);

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15); */
}
.cal-header-title{
    color: var(--Colors-Content-Action, #274D91);

    /* Heading/h3-bold */
    font-size: var(--fontSize-32, 26px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Heading-h3, 40px); /* 125% */     
}

.popup-table-container{
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto;
}
.ant-table-tbody{
    color: var(--Colors-Content-Default, #262D3C);

    /* Body/Body-lg-semibold */
    font-size: var(--fontSize-18, 14px);
    font-style: normal;
    font-weight: var(--fontWeight-semibold, 600);
    line-height: var(--Typography-Line-Height-Body-lg, 22px); /* 122.222% */ 
}

.cal-popup-main{
    padding: 15px 23.92px;
    margin-top: 25px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 14.72px;
    background: var(--Colors-Surface-Default, #FFF);
}