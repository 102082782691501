.customTable .ant-table-body {
  overflow-y: auto !important;
}

/* .customTable .ant-table-body table {
  margin-top: -2rem;
} */

.ant-table-tbody tr {
  background: #ffffff;
}
.ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead > tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}
.ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 0 0 0 #fafafa;
}

.customTable .ant-table-tbody > tr > td {
  border: none;
}
.ant-table-wrapper .ant-table-thead > tr > td {
  border-bottom: 0px !important;
}
/* 
.ant-table-thead > tr > th {
  padding: 12px !important;
} */

.ant-table-thead > tr > th {
  /* display: flex!important; */
  text-align: center!important;
  justify-content: space-between!important;
  width: 300px!important;
}
.ant-table-pagination > li {
 border: 1px solid #eff0f4 !important;
}
.ant-table-pagination > .ant-pagination-item-active,
.ant-table-pagination > .ant-pagination-item:hover {
  background-color: var(--primary-dark) !important;
}

.ant-table-pagination > .ant-pagination-item-active > a,
.ant-table-pagination > .ant-pagination-item:hover > a {
  color: white;
  
}
:where(.ant-table-wrapper) .ant-table-pagination.ant-pagination {
  margin: 27px 0;
  /* position: fixed !important;
  bottom: 1rem !important;
  right: 3rem !important; */

}
.ant-switch {
  background-color: #db1a1ab2;
}
.ant-table-body tr {
  background-color: white;
  box-shadow: 0px 2px 0px -1px #c2c3c8;
  overflow: hidden;
}
.ant-table-header {
  border-radius: 29px 29px 0px 0px;
  position: relative;
  z-index: 5;
  /* padding: 0px 25px; */
  background-color: #f4f7fc;
}
.ant-table-thead >tr>th {
  background-color: #f4f7fc !important;
  width: fit-content;
}
.ant-table-body tr td {
  overflow: hidden;
}
.ant-table-pagination > li.ant-pagination-total-text {
  border: 0px !important;
  font-weight: 700;
}
.ant-table-wrapper .ant-table-column-title {
  flex-grow: 0;
  min-width: fit-content;
}

.ant-table-tbody > tr > td {
  text-align: center;
}
.ant-table-tbody > tr > td >div  {
  text-align: center;
  justify-self:  center;
}
/* .ant-table-tbody > tr > td  > div{
  background-color: #254e90;
  text-align: center;
} */
/* 
.ant-table-thead > tr > th:first-of-type,
.ant-table-thead > tr > th:nth-of-type(2) {
  padding: 12px 37px !important;
  background-color: aqua;
  } */
  
  /*




.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 0px;
}



.ant-table table {
  border-spacing: 0 0.7rem;
}


.ant-table-body {
  padding: 0px 25px;
  padding-top: 15px;
}


.ant-table-body tr td.ant-table-cell-row-hover {
  background-color: white !important;
}



.ant-table-wrapper td.ant-table-column-sort {
  background-color: transparent;
}


.ant-table-wrapper .ant-table-column-sorters {
  justify-content: flex-start;
  gap: 0.7rem;
}
.record{
  background-color: #34a567 !important;
  padding: 1rem;
}




.ant-pagination-options > .ant-pagination-options-size-changer > .ant-select-selector{
  background-color:#f5f8fd;
  color: #32599c;
} */

/* 
.ant-table-column-sorters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: #f4f7fc;
  border-radius: 4px; 
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.ant-table-column-sorters .anticon {
  color: #24508d; 
  margin-left: 8px; 
}


.ant-table-column-sorters:hover {
  background-color: #df2a2a; 
}

.ant-table-column-sorters .ant-table-column-sorter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background-color: rgb(38, 131, 87);
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.ant-table-column-sorters .ant-table-column-sorter-up.active,
.ant-table-column-sorters .ant-table-column-sorter-down.active {
  color: #34a567; 
}

.ant-table-column-sorters .ant-table-column-sorter-up,
.ant-table-column-sorters .ant-table-column-sorter-down {
  color: #32247e;
}



.ant-tooltip {
  background-color: #2858aa;
  border-radius: 4px; 
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); 
  color: black !important;
}

.ant-tooltip-arrow {
  border-color: #f4f7fc; 
}

.ant-tooltip-inner {
  background-color: #4770b8; 
  color: #24508d;  
  border-radius: 4px;
  padding: 8px;
}

.ant-tooltip-inner .anticon {
  color: #24508d; 
  margin-left: 8px; 
} */


.ant-table-thead > tr>  .ant-table-cell {
  white-space: nowrap; 
  color: var(--Colors-Surface-Action, #274D91) !important;

font-size: var(--fontSize-18, 18px) !important;
font-style: normal !important;
font-weight: var(--fontWeight-bold, 700) !important;
line-height: var(--Typography-Line-Height-Body-lg, 22px) !important; 
}
