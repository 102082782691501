.alerts-main {
    padding: 48px 44px;
    border-radius: 16px;
    background: var(--Colors-Surface-Default, #FFF);
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.active-btn{
background: var(--Colors-Surface-Action, #274D91)!important;
color : #fff!important;
}
.filter-container{
    display: flex;
padding: 12px 32px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 12px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
}