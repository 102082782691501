
.ant-modal-footer{
    justify-self: end !important ;
}
.price-modal{
    display: flex;
    width: 1163px;
    padding: 48px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    /* align-items: flex-end; */
    justify-self:center;
    gap: var(--Spacing-xxxl, 32px);
}
.ant-modal-wrap{
    display: flex;  

}
.zone-side{
    display: flex;
    flex-direction: column;
width: 199px;
padding: 35px 36.8px;
justify-content: space-between;
align-items: center;
align-content: center;
row-gap: var(--Spacing-xxxl, 32px);
flex-shrink: 0;
flex-wrap: nowrap;
background: var(--Colors-Surface-Transp-Active, #F4F7FC);

}
.price-input > span > .ant-input {
    border: none;
    font-size: var(--fontSize-18, 18px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Body-lg, 22px) !important;
}

.price-header-container{
    display: flex;
width: 1155px;
padding: 23.92px 30px 23.92px 254px;
justify-content: space-between;
align-items: center;
background: var(--Colors-Surface-Transp-Active, #F4F7FC);
}

.zone-title{
    flex-shrink: 0;
    color: var(--Colors-Surface-Action, #274D91);
font-size: var(--fontSize-18, 18px);
font-style: normal;
font-weight: var(--fontWeight-bold, 700);
line-height: var(--Typography-Line-Height-Body-lg, 22px); /* 122.222% */
}

.prices-table-row {
    display: flex;
    justify-content: space-between;
    width: 100%!important;
    padding-top: var(--Spacing-xxxl, 32px);
    font-size: var(--fontSize-18, 18px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Body-lg, 22px) !important;
}
.ant-input-group-addon{
    border: none !important;
    background-color:  white !important;
    font-size: var(--fontSize-18, 18px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeight-semibold, 600) !important;
    line-height: var(--Typography-Line-Height-Body-lg, 22px) !important;
    padding-left: 0px !important;
}

.ant-modal-title{
    display: flex;
width: 1163px;
padding: 48px 0px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: var(--Spacing-xxxl, 32px);


/* Heading/h4-bold */
font-size: var(--fontSize-28, 28px) !important;
font-style: normal !important;
font-weight: var(--fontWeight-bold, 700) !important;
line-height: var(--Typography-Line-Height-Heading-h4, 32px) !important; /* 114.286% */
}
#priceList{
    width: 100%;
}

.price-modal .ant-input {
    background: transparent;
    box-shadow: none;
    color: inherit;
    text-align: center; /* Center-align the text */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .price-modal .ant-input-group-addon {
    padding: 0;
    /* margin-left: 4px; */
    font-size: 1rem !important; /* Adjust font size for better fit */
    color: #666; /* Optional: Change color for contrast */
  }
  
  .price-modal .ant-input:focus {
    outline: none;
    border-color: transparent;
  }
  


  .business-dropDown{
    /* display: flex; */
height: 45px;
padding: 16.5px 18px;
justify-content: center;
align-items: center;
gap: var(--Spacing-md, 12px);
border-radius: 9px;
background: var(--Colors-Surface-Default, #FFF);
cursor: pointer;
/* Elevations/E1 */
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
color: var(--Colors-Content-Primary, #262626);

/* Body/Body-lg-semibold */
font-size: var(--fontSize-18, 18px);
font-style: normal;
font-weight: var(--fontWeight-semibold, 600);
line-height: var(--Typography-Line-Height-Body-lg, 22px); /* 122.222% */
  }

  .ant-dropdown-menu-item{
    display: flex;
height: 48px;
padding: 12px 16px;
/* flex-direction: column; */
/* justify-content: start; */
/* align-items: start; */
gap: 8px;
color: var(--Colors-Content-Action-2, #274D91);
text-align: center;

/* Body/Body-lg-semibold */
font-size: var(--fontSize-18, 18px);
font-style: normal;
font-weight: var(--fontWeight-semibold, 600);
line-height: var(--Typography-Line-Height-Body-lg, 22px); /* 122.222% */
/* align-self: stretch; */
/* border-radius: 8px; */
  }

  .price-modal .ant-modal-content {
    max-width: 90vw; /* Adjust this value as needed */
    overflow: auto;
  }
  
  .price-modal .ant-table-wrapper {
    overflow-x: auto;
  }
  
  .price-modal .ant-table {
    min-width: 800px; /* Adjust this value based on your table's width */
  }