.home {
    position: relative;
    padding: 0 4%;
    height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: end;
    background-image: url('../../assets/sawaBackground.png')!important;
    /* background-image: url('../../assets/home_bg.jpg')!important; */


  }
  
  .home-image{
    transform: scaleX(-1);
  }
  .logo{
    position: absolute;
    top: 50%;
    z-index: 1;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    width: 100%;
    max-width: 400px;
  }
