.card-container {
    width: calc((100% / 3) - 1.5rem);
    padding: 26px 24px;

    border-radius: 12px;
    background: #FFF;

    /* Elevations/E1 */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
}

.top-line {
    color: #000;
    font-family: var(--fontFamily-Open-Sans, "Open Sans");
    font-size: var(--fontSize-18, 18px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Body-lg, 22px);
}

.doc-type {
    color: #000;
    align-self: stretch;
    font-family: var(--fontFamily-Open-Sans, "Open Sans");
    font-size: var(--fontSize-14, 14px);
    font-style: normal;
    font-weight: var(--fontWeight-bold, 700);
    line-height: var(--Typography-Line-Height-Caption-lg, 16px);
    /* 114.286% */
}

.vehicle-pic {
    width: 86px;
    height: 42.065px;
    flex-shrink: 0;
    aspect-ratio: 86.00/42.07;
}

.driver-pic {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    aspect-ratio: 1/1;
    border-radius: 48px;
}

.card-date {
    align-self: stretch;
    color: var(--Colors-Content-Error, #C63333);
    font-family: var(--fontFamily-Open-Sans, "Open Sans");
    font-size: var(--fontSize-14, 14px);
    font-style: normal;
    font-weight: var(--fontWeight-semibold, 600);
    line-height: var(--Typography-Line-Height-Caption-lg, 16px);
}