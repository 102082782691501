.ant-layout-sider {
  box-shadow: 0px 2px 16px 0px #6868681A;
  background-color: white !important;
  display: inline-flex;
/* padding: 52px var(--Spacing-lg, 1px); */
padding-top: 32px;
align-items: flex-start;
gap: 10px;
border-radius: var(--Radius-xl, 20px);
background: var(--Colors-Surface-Default, #FFF);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
/* Hide scrollbar in Firefox */

}

.menu-container{
  display: flex;
flex-direction: column;
align-items: center;
margin-top: 10px;
/* gap: 37px; */
}

.menu-item-wrapper{
  display: flex;
width: 108.781px;
flex-direction: column;
justify-content: center;
align-items: center;
/* gap: var(--Spacing-sm, 8px); */
}

.menu-item-svg-icon{
  display: flex;
height: 72px;
padding:  var(--Spacing-xxl, 24px);
align-items: center;
justify-content: center;
border-radius: var(--Radius-lg, 16px);
margin-top: 10px;

/* Elevations/E1 */
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
}

.ant-menu-item{
  height: fit-content!important;
  margin-top: 0px!important;
}

.menu-title-text{
  margin-top:var(--Spacing-sm, 8px) ;
  width: 96px;
  color: var(--Colors-Content-Default, #262D3C);
text-align: center;
text-align: center;
white-space: normal; /* Allow text to wrap */
word-wrap: break-word; /* Break long words if necessary */

/* Body/Body-lg-semibold */
/* font-family: var(--fontFamily-Open-Sans, "Open Sans"); */
font-size: var(--fontSize-18, 18px) !important;
font-style: normal !important;
font-weight: var(--fontWeight-semibold, 600) !important;
line-height: var(--Typography-Line-Height-Body-lg, 22px) !important; /* 122.222% */

}
